import React from "react";
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import { useState } from "react";



import IconWhats from "../../img/header/whats";
import IconBe from "../../img/header/be";
import IconInsta from "../../img/header/insta";

import theBranding from '../../img/pageseven/brandingExperience.svg';

import toBeUnique from '../../img/pageseven/toBeUnique.svg';
import toBeAwesome from '../../img/pageseven/toBeAwesome.svg';
import toBeCreative from '../../img/pageseven/toBeCreative.svg';
import toBeEspecial from '../../img/pageseven/toBeEspecial.svg';


import IconWhatsBlack from "../../img/header/WhatsBlackMovil.svg";
import IconBeBlack from "../../img/header/BeBlackFooter.svg";
import IconInstaBlack from "../../img/header/InstaBlackFooter.svg";


import TBE from "../../img/header/tbe";

const PageSeven = () =>{

    const [chart,Chart] = useState(getRandom())

    function getRandom() {
        let min = 0;
        let max = 4;
        return Math.floor(Math.random() * (max - min) + min);
    }

    return(
        <div className="containerContentPageSeven" id="PaginaFooterBlack">
            <div className="containerInformation">
                <div className="titleInformation">
                {
                        chart == 0 ?
                            (<img loading="lazy" src={toBeUnique} alt="React Logo" width="20%"  style={{filter: 'invert(100%)'}}/>)
                        :''
                    }
                    {
                        chart == 1 ?
                            (<img loading="lazy" src={toBeAwesome} alt="React Logo" width="20%"  style={{filter: 'invert(100%)'}}/>)
                        :''
                    }
                    {
                        chart == 2 ?
                            (<img loading="lazy" src={toBeCreative} alt="React Logo" width="20%"  style={{filter: 'invert(100%)'}} />)
                        :''
                    }
                    {
                        chart == 3 ?
                            (<img loading="lazy" src={toBeEspecial} alt="React Logo" width="20%"  style={{filter: 'invert(100%)'}} />)
                        :''
                    }
                </div>  
                <div className="informationBotton">
                    <text className="textTwo">
                        <img loading="lazy" src={theBranding} width="100%"
                            style={{filter: 'invert(100%)'}}
                        />                        
                    </text>
                    <div className="iconsBottoms">
                        <a href="https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0" target="_blank"><img src={IconWhatsBlack}></img></a>
                        <a href="https://www.instagram.com/tbestudio_/" target="_blank"><img src={IconInstaBlack}></img></a>
                        <a href="https://www.behance.net/tbebranding" target="_blank"><img src={IconBeBlack}></img></a>                                                                    
                    </div>
                 
                    <text className="textThree">
                        <text className="icon">©</text> 2022 theBrandingExperts. all Rights Reserved
                    </text>
                </div>
            </div>
            <div className="bottonend">
                <div className="designByBlack">
                    <TBE></TBE>
                </div>             
            </div>
        </div>
    )
}
export default PageSeven;