import React from "react";
import './index.scss'
import './ipad.scss'
import './mobile.scss'
import { useState } from "react";





import IconWhats from "../../img/header/whats";
import IconInsta from "../../img/header/insta";
import IconBe from '../../img/header/be'

import IconWhatsSv from '../../img/header/WhatsWhiteMovil.svg'
import IconInstaSv from '../../img/header/InstaMovil.svg' 
import IconBeSv from "../../img/header/BeOpenMovil.svg";

import theBranding from '../../img/pageseven/brandingExperience.svg';


import toBeUnique from '../../img/pageseven/toBeUnique.svg';
import toBeAwesome from '../../img/pageseven/toBeAwesome.svg';
import toBeCreative from '../../img/pageseven/toBeCreative.svg';
import toBeEspecial from '../../img/pageseven/toBeEspecial.svg';

import TBE from '../../img/header/tbe.jsx'
const PageSeven = () =>{

    const [chart,Chart] = useState(getRandom())

    function getRandom() {
        let min = 0;
        let max = 4;
        return Math.floor(Math.random() * (max - min) + min);
    }

    return(
        <div className="containerContentPageSeven" id="PaginaFooter">
            <div className="containerInformation" style={{
                borderTop:'1px solid rgba(255,255,255, 0.5)',
                borderBottom:'1px solid rgba(255,255,255, 0.5)'
            }}>
                <div className="titleInformation">
                    {
                        chart == 0 ?
                            (<img loading="lazy" src={toBeUnique} alt="React Logo" />)
                        :''
                    }
                    {
                        chart == 1 ?
                            (<img loading="lazy" src={toBeAwesome} alt="React Logo"/>)
                        :''
                    }
                    {
                        chart == 2 ?
                            (<img loading="lazy" src={toBeCreative} alt="React Logo" />)
                        :''
                    }
                    {
                        chart == 3 ?
                            (<img loading="lazy" src={toBeEspecial} alt="React Logo"/>)
                        :''
                    }
                </div>  
                <div className="informationBotton">
                    <text className="textTwo">                         
                         <img loading="lazy" src={theBranding} alt="React Logo" width="100%"/>
                    </text>
                    <div className="iconsBottoms">
                        <a href="https://api.whatsapp.com/send/?phone=12122030151&text&app_absent=0" target="_blank"><img src={IconWhatsSv}></img></a>
                        <a href="https://www.instagram.com/tbestudio_/" target="_blank"><img src={IconInstaSv}></img></a>
                        <a href="https://www.behance.net/tbebranding" target="_blank"><img src={IconBeSv} className="beImage"></img></a>                                                                    
                    </div>
                
              
                    <text className="textThree">
                        <text className="icon">© 2024 theBrandingExperts. all Rights Reserved</text>
                    </text>
                </div>
            </div>
            <div className="bottonend">
                <div className="designBy">
                    <TBE fill1="#FFF"></TBE>
                </div>                
            </div>
        </div>
    )
}
export default PageSeven;